<template>
    <div class="app-container">
        <el-collapse v-model="activeName">
            <el-collapse-item name="1">
                <template slot="title">
                    <title-icon/>{{ $t('system.instructions') }}
                </template>
                <el-tree
                        :data="data"
                        node-key="id">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span @click="clickNode(node,data)">{{ node.label }}</span>
                </span>
                </el-tree>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import {getSystemLogList,getDownLoadSystemFile} from '@/api/ruge/ops/systemLog'

    export default {
        name: 'SystemLog',
        data() {
            return {
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'label',
                },
                activeName: '1'
            }
        },
        created() {
            this.getSystemLogList();
        },
        methods: {
            getSystemLogList() {
                getSystemLogList().then(response => {
                    this.recursiveAttr(response)
                })
            },
            //递归修改属性及数据结构
            recursiveAttr(row){
                let data = row.map(v => {
                    v.children = v.subFileList;
                    v.label = v.fileName;
                    if(v.subFileList != undefined){
                        this.recursiveAttr(v.subFileList);
                    }
                    return v
                })
                this.data = data;
            },
            clickNode(node,data){
                let rootNode = node;
                for(;;) {
                    let tmpNode = rootNode.parent;
                    if(tmpNode) {
                        rootNode = tmpNode
                    } else {
                        break
                    }
                }
                if(node.isLeaf){
                    this.$confirm(this.$t("system.downloadPrompt"), this.$t('commons.prompt'), {
                        confirmButtonText: this.$t('commons.confirm'),
                        cancelButtonText: this.$t('commons.cancel'),
                        type: 'info'
                    }).then(() => {
                        window.location.href = getDownLoadSystemFile({"host":rootNode.data[0].host, "fileName":data.fileName,"fileDir" : data.fileDir});
                    });
                }
            },
        }
    }

</script>

<style scoped>

</style>
