import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

export function getSystemLogList() {
  return request({
    url: envInfo.bgApp.loggerPath + '/ops/log/findSystemLog/list',
    method: 'get',
  })
}


export function getDownLoadSystemFile(params) {
  return envInfo.bgApp.loggerPath + `/param/ops/download?dlType=SystemLogDownload&ht=${params.host}&fd=${params.fileDir}&fn=${params.fileName}`;
}
